import Header from 'components/Header'
import Menu from 'components/Menu'
import FooterNew from 'components/FooterNew'
import SectionFirstPrevTech from './components/SectionFirstPrevTech'
import Hero from './components/Hero'
import SectionForm from 'components/SectionForm'
import Sidebar from 'components/Sidebar'

import enhancer from './hooks'


const Management = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <SectionFirstPrevTech />
      <FooterNew />
    </div>
  )
}

export default enhancer(Management)
