import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import enhancer from '../../hooks'

const SectionFirstPrevTech = ({ onClickSidebar }) => {
  return (
    <section className="section-first-prevtech">
      <div className="container section-first-prevtech__content">
        <Typography
          as="h2"
          variant="headline4"
          className="section-first-prevtech__title">
            Oferecemos o melhor para o futuro do seu time
        </Typography>
        <Typography
          as="p"
          variant="subtitle"
          className="section-first-prevtech__text">
          Para conhecer toda nossa carteira de fundos, entre em contato com a gente.
        </Typography>

        <Button
          onClick={onClickSidebar}
          label=" Agendar conversa agora"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="text"
          customClass="section-first-prevtech__button"
        />
      </div>
    </section>
  )
}

export default enhancer(SectionFirstPrevTech)