import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import Img from 'components/Img'

const SectionReadOurLetter = ({ cmsData }) => {
  const { pdf } = cmsData
  
  return (
    <section className="section-read-letter" data-show>
      <figure className="section-read-letter__image-wrapper">
        <Img
          className="section-read-letter__image"
          src='/fund-graphic.png'
          alt="Imagem ilustrativa de um grafico de pizza"
        />
      </figure>
      <div className="section-read-letter__description">
        <Typography as="h2" variant="headline5" className="section-read-letter__title">
          Veja todos os fundos disponíveis para contratação
        </Typography>
        <Typography as="p" variant="body1">
        Um grande portfolio de fundos especializados em previdência, desenhados para que todos os perfis de investimento cresçam a longo prazo. Ah, e com taxas reduzidas, viu?
        </Typography>
        {/*
        <Button
          label="Confira a tabela"
          as="a"
          target="_blank"
          href={pdf.filename}
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="text"
          customClass="section-read-letter__button"
        />
        */}
      </div>
    </section>
  )
}

export default SectionReadOurLetter
