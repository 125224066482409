export const sliderImages = [
  {
    src: 'logos-gestao-anga@2x.png',
    alt: 'Logo angá',
  },
  {
    src: 'logos-gestao-ARX@2x.png',
    alt: 'Logo ARX',
  },
  {
    src: 'logos-gestao-BNP@2x.png',
    alt: 'Logo BNP',
  },
  {
    src: 'logos-gestao-BTG@2x.png',
    alt: 'Logo BTG',
  },
  {
    src: 'logos-gestao-Gauss@2x.png',
    alt: 'Logo Gauss',
  },
  {
    src: 'logos-gestao-Indosuez@2x.png',
    alt: 'Logo Indosuez',
  },
  {
    src: 'logos-gestao-Mercantil-do-Brasil@2x.png',
    alt: 'Logo Mercantil do Brasil',
  },
  {
    src: 'logos-gestao-Schoders@2x.png',
    alt: 'Logo Schoders',
  },
  {
    src: 'logos-gestao-Valora@2x.png',
    alt: 'Logo Valora',
  },
  {
    src: 'logos-gestao-AdamCapital@2x.png',
    alt: 'Logo Adam Capital',
  },
  {
    src: 'logos-gestao-Alaska@2x.png',
    alt: 'Logo Alaska',
  },
  {
    src: 'logos-gestao-AZQuest@2x.png',
    alt: 'Logo AZQuest',
  },
  {
    src: 'logos-gestao-HIX@2x.png',
    alt: 'Logo HIX',
  },
  {
    src: 'logos-gestao-Ibiuna@2x.png',
    alt: 'Logo Ibiuna',
  },
  {
    src: 'logos-gestao-Indie@2x.png',
    alt: 'Logo Indie',
  },
  {
    src: 'logos-gestao-JGP@2x.png',
    alt: 'Logo JGP',
  },
  {
    src: 'logos-gestao-Verde@2x.png',
    alt: 'Logo Verde',
  },
  {
    src: 'logos-gestao-Vinci@2x.png',
    alt: 'Logo Vinci',
  },
  {
    src: 'logos-gestao-Garde@2x.png',
    alt: 'Logo Garde',
  },
  {
    src: 'logos-gestao-Giant@2x.png',
    alt: 'Logo Giant',
  },
  {
    src: 'logos-gestao-Spx@2x.png',
    alt: 'Logo Spx',
  },
  {
    src: 'logos-gestao-Studio@2x.png',
    alt: 'Logo Studio',
  },
  {
    src: 'logos-gestao-Truxt@2x.png',
    alt: 'Logo Truxt',
  },
  {
    src: 'logos-gestao-Kadima@2x.png',
    alt: 'Logo Kadima',
  },
  {
    src: 'logos-gestao-Bahia@2x.png',
    alt: 'Logo Bahia',
  },
  {
    src: 'logos-gestao-Canvas@2x.png',
    alt: 'Logo Canvas',
  },
  {
    src: 'logos-gestao-Leblon@2x.png',
    alt: 'Logo Leblon',
  },
  {
    src: 'logos-gestao-Capitania@2x.png',
    alt: 'Logo Capitania',
  },
  {
    src: 'logos-gestao-Constellation@2x.png',
    alt: 'Logo Constellation',
  },
  {
    src: 'logos-gestao-Franklin@2x.png',
    alt: 'Logo Franklin',
  },
  {
    src: 'logos-gestao-Legacy@2x.png',
    alt: 'Logo Legacy',
  },
  {
    src: 'logos-gestao-Occam@2x.png',
    alt: 'Logo Occam',
  },
]
